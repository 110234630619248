import React from "react";
import { Snackbar, Alert } from "@mui/material";
function AlertComponenet(props) {
  const { open, handleClose } = props;
  console.log("reacin here")
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Successfully Submitted!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertComponenet;