import React, { useContext, useState } from 'react';
import axios from 'axios';

import history from '../utils/history';
import Context from '../utils/context';
import TextField from '@mui/material/TextField';
import 'react-quill/dist/quill.snow.css'
import { Button } from '@mui/material';
import QuillEditor from '../utils/QuillEditor'
import { Container } from '@mui/system';
import AlertComponenet from '../utils/SnakBar'

const AddPost = () => {
  const context = useContext(Context)
  const [convertedText, setConvertedText] = useState("");
  
  const handleSubmit = (event) => {
    event.preventDefault()
    const user_id = context.dbProfileState[0].uid
    const username = context.dbProfileState[0].username
    const data = {
      title: event.target.title.value,
      desc: event.target.desc.value,
      body: convertedText,
      username: username,
      uid: user_id
    }
   
    const access_token = localStorage.getItem('access_token')
    console.log("post data",convertedText)
    
    axios.post(process.env.REACT_APP_API +'/api/post/posttodb', data, { headers: { Authorization: access_token } })
      .then(response => console.log(response))
      .catch((err) => console.log(err))
      .then(setTimeout(() => history.replace('/posts'), 200))
      
  }


  return (
    <Container maxWidth="md">
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
        fullWidth
          id='title'
          label='Blog Title'
          margin='normal'
          required={true}
          inputProps={{ maxLength: 200 }}
        />
        <br />
        <TextField
        fullWidth
          id='desc'
          label='Description'
          margin='normal'
          required={true}
          inputProps={{ maxLength: 1000 }}

        />
        <br />
        <br />

        <QuillEditor onchange={setConvertedText} value={convertedText} />
        <Button size="medium" variant="contained" style={{ float: "right" }}
          sx={{ justifyContent: "flex-end" }} type='submit'> Submit </Button>
      </form>
     
      <Button size="medium" variant="contained" color="success" onClick={() => history.replace('/posts')}> Cancel </Button>
    </div>
    
    </Container>
  )
}



export default AddPost;
