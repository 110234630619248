import React, { useContext, useState, useEffect } from 'react';
import Context from '../utils/context';

import { Link } from 'react-router-dom';
import history from '../utils/history';
import axios from 'axios';
import { Container } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = () => {
  const context = useContext(Context)

  const [stateLocal, setState] = useState({
    open: false,
    post_id: null,
    posts: []
  })
  useEffect(() => {
    const user_id = context.dbProfileState[0].uid
    axios.get(process.env.REACT_APP_API + '/api/get/userposts', { params: { user_id: user_id } })
      .then((res) => setState({ ...stateLocal, posts: [...res.data] }))
      .catch((err) => console.log(err))
  }, [stateLocal.open])

  const handleClickOpen = (pid) => {
    console.log("hello from here")
    setState({ open: true, post_id: pid })
  }

  const handleClickClose = () => {
    
    setState({ open: false, post_id: null })
  }

  const DeletePost = () => {
    const access_token = localStorage.getItem('access_token')
    const post_id = stateLocal.post_id
    axios.delete(process.env.REACT_APP_API + '/api/delete/postcomments', { data: { post_id: post_id }, headers: { Authorization: access_token } })
      .then(() => axios.delete(process.env.REACT_APP_API + '/api/delete/post', { data: { post_id: post_id }, headers: { Authorization: access_token } })
        .then(res => console.log(res)))
      .catch(err => console.log(err))
      .then(() => handleClickClose())
      .then(() => history.replace('/profile'))
  }

  const RenderProfile = (props) => {

    return (
      <Card style={{ maxWidth: 700, marginBottom: '10px', paddingBottom: '80px', border: "1px solid red" }}>
        <div>
          <h1> <img src={props.profile.picture} alt="" /> {props.profile.nickname} </h1>
          <h4>Email ID: {props.profile.email}</h4>
          <h5>Name: {props.profile.name} </h5>
          <h6>Email Verified: {props.profile.email_verified ? <i>Yes</i> : <i>No</i>}</h6>
        </div>
      </Card>
    )
  }


  const RenderPosts = post => (
    <Card style={{ width: '500px', height: '200px', marginBottom: '10px', paddingBottom: '80px' }}>
      <CardHeader
        title={<Link to={{ pathname: '/post/' + post.post.pid, state: { post } }}>
          {post.post.title}
        </Link>}
        subheader={
          <div className="FlexColumn">
            <div className="FlexRow">
              {post.post.date_created}
            </div>
            <div className="FlexRow">
              <Link to={{ pathname: '/editpost/' + post.post.pid, state: { post } }}>
                <button>
                  Edit
                </button>
              </Link>
              <button onClick={() => handleClickOpen(post.post.pid)}>
                Delete
              </button>
            </div>
          </div>
        }
      />
      <CardContent>
        <span style={{ overflow: 'hidden' }}> {post.post.description} </span>
      </CardContent>

    </Card>
  );


  return (

    <div>
      <Container maxWidth="md">
        <div>
          <Link to={{ pathname: "/showmessages/" + context.dbProfileState[0].uid }}>
            <Button variant="contained" color="primary" type="submit">
              Show Messages
            </Button>
          </Link>
          <RenderProfile profile={context.profileState} />
        </div>
      </Container>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: 2,
            rowGap: 1,
          }}>
          {stateLocal.posts
            ? stateLocal.posts.map(post =>
              <RenderPosts post={post} key={post.pid} />)
            : null}

        </Box>
      </Container>
            
      <Dialog
        open={stateLocal.open}
        onClose={handleClickClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> Do you want to delete the post? </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
          >
            Deleteing Post
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => DeletePost()}>
              Agree
            </Button>
            <Button onClick={() => handleClickClose()}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

    </div>

  )
}



export default (Profile);
