
import * as React from 'react';
import MuiAlert from '@mui/material/Alert';

const truncate_description = (descStr) =>{

    let res =  descStr.length >= 150 ? descStr.substring(0, 150)+"...": descStr
    return res


}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export {truncate_description,Alert} ;