import auth0 from 'auth0-js'
import history from './history';


export default class Auth {
  
  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_domain,
    clientID: process.env.REACT_APP_clientID,
    redirectUri: process.env.REACT_APP_redirectUri,
    responseType: process.env.REACT_APP_responseType,
    scope: process.env.REACT_APP_scope
  })

  userProfile = {}
  

  login = () => {
      this.auth0.authorize()
  }

  handleAuth = () => {
    this.auth0.parseHash((err, authResult) => {
      if(authResult) {
        localStorage.setItem('access_token', authResult.accessToken)
        localStorage.setItem('id_token', authResult.idToken)
        localStorage.setItem('profile', JSON.stringify(authResult.idTokenPayload))
        
        let expiresAt = JSON.stringify((authResult.expiresIn * 1000 + new Date().getTime()))
        localStorage.setItem('expiresAt', expiresAt)
        setTimeout(() => { history.replace('/authcheck') }, 1000);
        this.getProfile();
        
      } else {
        console.log(err)
      }
    })
  }

  getAccessToken = () => {
    if(localStorage.getItem('access_token')) {
      const accessToken = localStorage.getItem('access_token')
      return accessToken
    } else {
      return null
    }
  }


  getProfile = () => {

    
    let accessToken = this.getAccessToken()
    
    if(accessToken != null) {
      
      this.auth0.client.userInfo(accessToken, (err, profile) => {
          if(profile) {
            this.userProfile = { profile }
            return this.userProfile
            
          }
      } )
    }
  }

  getUserProfile =()=> {
    return JSON.parse(localStorage.getItem('profile'))
  }


  logout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expiresAt')
    localStorage.removeItem('profile')
    setTimeout(() => { history.replace('/authcheck') }, 200);
  }

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expiresAt'))
    return new Date().getTime() < expiresAt
  }

}