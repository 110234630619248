import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';
import Context from '../utils/context';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CardActions from '@mui/material/CardActions';
import { makeStyles } from '@mui/styles';
import '../App.css';
import '../styles/pagination.css';
// import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { red } from '@mui/material/colors';
import { useTheme } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import useFetch from '../hooks/FetchAllPost';
import { Container } from '@mui/system';
import { truncate_description } from "../utils/anoFuncs"


import LinearProgress from '@mui/material/LinearProgress';

function LinearIndeterminate () {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress  color="secondary" />
    </Box>
  );
}


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "99%",
    [theme.breakpoints.down("md")] : {
    maxWidth: "60%"
    }
  },
  media: {
    height: 240
  }
}));

const Posts = (props) => {

  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  let { loading, error, list, lastPage } = useFetch(query, page);
  const loader = useRef(null);
  const context = useContext(Context)
  const classes = useStyles();
  const [searcv, setSearchV] = useState()

  useEffect(() => {
    const handleSearch = 
      setTimeout(()=>{
        setQuery(searcv)
      setPage(0)
      },500);
    return () =>{
      clearTimeout(handleSearch)
    }
  }
  ,[searcv])
  // code from here
  

  const handleObserver = useCallback((entries) => {

    const target = entries[0];
    if (target.isIntersecting) {

      setPage((prev) => prev + 2);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  const RenderPosts = (post) => {

  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

   return (
    <Card className={classes.root} >
     <CardHeader avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {post.post.author[0].toUpperCase()}
            </Avatar>
          }
            title={<Typography to={{ pathname: '/post/' + post.post.pid, state: { post } }}>
              {post.post.title}
            </Typography>}
            subheader={
              <div className="FlexColumn">
                <div className="FlexRow">
                  {moment(post.post.date_created).format('MMMM Do, YYYY | h:mm a')}
                </div>
                <div className="FlexRow">
                  By:
                  <Link style={{ paddingLeft: '5px', textDecoration: 'none' }}
                    to={{ pathname: "/user/" + post.post.author, state: { post } }}>
                    {post.post.author}
                  </Link>
                </div>
                <div className="FlexRow">
                  <i className="material-icons">thumb_up</i>
                  <div className="notification-num-allposts"> {post.post.likes} </div>
                </div>
              </div>
            }
          />
          <CardActions>
            <Grid container justifyContent="flex-end">
              {/* <Button size="small">Share</Button> */}
              <Button component={Link} to={{ pathname: '/post/' + post.post.pid, state: { post } }} size="small">view Blog</Button>
            </Grid>
          </CardActions>
          <CardMedia  
          className={classes.media}
            component="img"
            href= "google.com"
            image={post.post.previewlink}
            alt="blog_img"
          />

          <CardContent>
            <Typography style={{ overflow: 'hidden' }}> {truncate_description(post.post.description)} </Typography>
          </CardContent>
        </Card>
  );
      
  }
  return (
    <div>
      <div style={{ transition: 'opacity 2s ease' }}>
        <br />
        {context.authState
          ? <Link to="/addpost">
            <Button style={{ float: 'right', margin: '30px' }} variant="contained" color="primary">
              Add Post
            </Button>
          </Link>
          : <Link to="/signup">
            <Button style={{ float: 'right', margin: '30px' }} variant="contained" color="primary">
              Sign In
            </Button>
          </Link>
        }
      </div>
      <br />

      <TextField
        id="search"
        label="Search"
        margin="normal"
        onChange={(event) =>{
          setSearchV(event.target.value)
        }}
      />
      <h1>Featured Posts</h1>
      <hr />

      <Container fixed>
        <Box
          sx={{
            display: 'grid', gridTemplateColumns: "repeat(auto-fill, minmax(30em, 1fr))",
            columnGap: 3,
            rowGap: 3,

          }}>

          {list
            ? list.map(post =>
              <RenderPosts key={post.pid + 1000} post={post} />
            )
            : null
          }
        </Box>
      </Container>
      {loading && <p>{LinearIndeterminate() }</p>}
      {error && <p>{{ error }}</p>}
      {!lastPage && <div ref={loader} />}
    </div>
  )
}


export default Posts;
