import React, { useContext, useState } from 'react';
import axios from 'axios';
import history from '../utils/history';
import Context from '../utils/context';
import 'react-quill/dist/quill.snow.css'
import TextField from '@mui/material/TextField';
import {Button} from '@mui/material';
import QuillEditor from '../utils/QuillEditor'
import { Container } from '@mui/system';

const EditPost = (props) => {
  const context = useContext(Context)

  const [stateLocal, setState] = useState({ title: props.location.state.post.post.title,
                                            body: props.location.state.post.post.body,
                                            description:props.location.state.post.post.description
                                          })
  
  const [ConvertedText,setConvertedText] = useState(stateLocal.body);

  const handleTitleChange = (event) => {
    setState({...stateLocal, title: event.target.value })
  }

  const handleDescChange = (event) => {
    setState({...stateLocal, description: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const user_id = context.dbProfileState[0].uid
    const username = context.dbProfileState[0].username
    const pid = props.location.state.post.post.pid
    const title = stateLocal.title
    const body = ConvertedText

    const desc = stateLocal.description

    const data = {title: title,
                  body: body,
                  pid: pid,
                  uid: user_id,
                  username: username,
                  desc: desc
                 }
    const access_token = localStorage.getItem('access_token')
    axios.put(process.env.REACT_APP_API +"/api/put/post", data,{ headers: { Authorization: access_token }})
      .then(res => console.log(res))
      .catch(err => console.log(err))
      .then(setTimeout(() => history.replace('/profile'), 700 ))
  }


    return(
      <Container maxWidth="md">
        <div>
          <form onSubmit={handleSubmit}>
            <TextField
              id='title'
              label='title'
              margin="normal"
              value={stateLocal.title}
              onChange={handleTitleChange}
              inputProps={{ maxLength: 20 }}
              required={true}
            />
            <br />
            <TextField
          id='desc'
          label='Description'
          margin='normal'
          required={true}
          onChange = {handleDescChange}
          value={stateLocal.description}
          inputProps={{ maxLength: 100 }}

        />
        <QuillEditor onchange={setConvertedText} value={ConvertedText} />
        {/* <ReactQuill
          theme='snow'
          id='body'
          value={stateLocal.body}
          onChange={setConvertedText}
          style={{ minHeight: '300px' }}
        /> */}
          <br />
          <Button  size="medium" variant="contained" type="submit" style={{float:'right'}}> Submit </Button>
          </form>
          <Button color="success" size="medium" variant="contained" onClick={() => history.goBack()}> Cancel </Button>
        </div>

      </Container>
    )}



export default EditPost;
