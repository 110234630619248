import React, { useEffect, useContext } from 'react';
import history from './history';
import Context from './context';

import axios from 'axios';


const AuthCheck = () => {
  const context = useContext(Context)
  useEffect(() => {
    if (context.authObj.isAuthenticated()) {
      const profile = context.authObj.getUserProfile()
      console.log(profile)
      if (Object.keys(profile).length !== 0) {
        context.handleUserLogin()
        context.handleUserAddProfile(profile)
        axios.post(process.env.REACT_APP_API + '/api/posts/userprofiletodb', { "profile": profile })
          .then(res => context.handleAddDBProfile(res.data))
          .then(history.replace('/'))
      }
      else {
        console.log("in auth check")


      }
    }
    else {
      context.handleUserLogout()
      context.handleUserRemoveProfile()
      context.handleUserRemoveProfile()
      history.replace('/')
    }
  }, [context.authObj.userProfile, context])

  return (
    <div>
    </div>
  )
}




export default AuthCheck;
