import React, { useContext, useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import history from './utils/history';
import Context from './utils/context';
import AuthCheck from './utils/authcheck';
import Home from './hooks/home';
import Header from './hooks/header';
import Callback from './hooks/callback';
import SignUp from './hooks/signup';
import Profile from './Profile/profile';
import ShowUser from './Profile/showuser';
import SendMessage from './Profile/sendmessage';
import ShowMessages from './Profile/showmessages';
import ReplytoMessage from './Profile/replytomessage';
import Posts from './Blog/posts';
import AddPost from './Blog/addpost';
import ShowPost from './Blog/showpost';
import EditPost from './Blog/editpost';
import InitAuth from './utils/InitAuth';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';

const PrivateRoute = ({ component: Component, auth }) => {


  return (
    <Route render={props => auth === true
      ? <Component auth={auth} {...props} />
      : <Redirect to = {{ pathname: '/authcheck' }} />
    }
    />
  )

}


const Routes = () => {
  let lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  let darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const context = useContext(Context)
  const [theme, setTheme] = useState(lightTheme)

  const changeTheme = mode => {
    if (!mode) {
      setTheme(darkTheme)
    }
    else {

      setTheme(lightTheme)
    }
  }

  InitAuth()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Router history={history} >
          <Header func={changeTheme} />
          <div>
            <Switch>
              <Route exact path='/' component={Posts} />

              <Route path='/authcheck' component={AuthCheck} />
              <Route path='/signup' component={SignUp} />

              <Route path='/posts' component={Posts} />
              <Route path='/post/:pid' component={ShowPost} />

              <Route path='/addpost' component={AddPost} />

              <Route path="/user/:name" component={ShowUser} />

              <PrivateRoute path='/profile'
                auth={context.authState}
                component={Profile} />

              <PrivateRoute path='/editpost/:pid' component={EditPost} auth={context.authState} />

              <PrivateRoute path="/sendmessage"
                auth={context.authState}
                component={SendMessage} />

              <PrivateRoute path="/showmessages/:id"
                auth={context.authState}
                component={ShowMessages} />
              <PrivateRoute path="/replytomessage"
                auth={context.authState}
                component={ReplytoMessage} />

              {/* <PrivateRoute path="/profile"
                            auth={context.authState}
                            component={Profile} /> */}
              <Route path='/callback'
                render={(props) => {
                  context.handleAuth(props);
                  return <Callback />
                }} />

            </Switch>
          </div>
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default Routes;
