import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
const Callback = props => (
  <Box sx={{ display: 'flex' }}>
    <LinearProgress  />
  </Box>
);

export default Callback;
