import React from 'react';
import ContextState from './context_state_config';

const App = () => {

    return(
      <div>
      <ContextState />
      </div>
 
    )
}


export default App;
