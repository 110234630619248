"use sctrict";
import { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import AdbIcon from '@mui/icons-material/Adb';
import Context from '../utils/context';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';

const pages = [{ 'text': 'Blog', 'link': '/posts', 'key': 2 }];

const ResponsiveAppBar = (props) => {

  const context = useContext(Context)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [dark, setDark] = React.useState(true)
  const open = Boolean(anchorElUser);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar color='default' position="sticky" >
        <Container maxWidth="xl">
          <Toolbar>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem>
                  <Button sx={{ my: 1, color: 'black', }} href={process.env.REACT_APP_PORTFOLIO} rel="noopener noreferrer" >Home</Button>

                </MenuItem>
                {pages.map((page) => (
                  <MenuItem key={page.key} onClick={handleCloseNavMenu}>
                    <Button component={Link} sx={{ my: 1, color: 'black', }} key={page.key} to={page.link} >{page.text}</Button>
                  </MenuItem>

                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button sx={{ my: 1, color: 'black', }} href={process.env.REACT_APP_PORTFOLIO} rel="noopener noreferrer"   >Home</Button>
              {pages.map((page) => (
                <Button
                  size="medium"
                  component={Link}
                  to={page.link}
                  key={page.key}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 1, color: 'black', }}
                >
                  {page.text}
                </Button>
              ))}


            </Box>
            {/* <IconButton sx={{ ml: 1 }} onClick={handlechangetheme} color="inherit">
            {dark ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton> */}
            {!context.authState
              ? <Button color="secondary" onClick={() => context.authObj.login()}>Login</Button>
              : <Box sx={{ flexGrow: 0 }}>

                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu}
                    open={open}
                    onClose={handleCloseUserMenu} sx={{ p: 0 }}
                  >
                    <Avatar alt="R" src="" />
                  </IconButton>
                </Tooltip>

                <Menu
                  sx={{ mt: '50px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}

                >

                  <MenuItem onClick={handleClose}>
                    <Avatar fontSize="small" /> <Typography component={Link} style={{ textDecoration: 'none', color: 'black' }} to='/profile' textAlign="center" > Profile</Typography>
                  </MenuItem>

                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography onClick={() => context.authObj.logout()} textAlign="center">Logout</Typography>
                  </MenuItem>

                </Menu>

              </Box>
            }


          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
};


export default ResponsiveAppBar;
