import * as ACTION_TYPES from '../actions/action_types'

export const initialState = {
  link: null,
}

export const URLReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.ADD_PREVIEW_LINK:
      return {
        ...state,
        link: action.payload,
      }
    default:
      
      return state
  }
}
