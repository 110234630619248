import React, { useMemo, useState, useRef, useEffect } from 'react';
import ReactQuill, { Quill } from "react-quill"
import 'react-quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);

export default function QuillEditor({
    value,
    onchange,
}) {

    const [description, setDescription] = useState(value || "");

    useEffect(() => {
        if (value) {
            setDescription(value);
        }
    }, []);

    const handleChange =(val)=>{
        setDescription(val)
        onchange(val);

    }

    const quillRef = useRef(); // the solution

    const imageHandler = () => {
        // get editor

        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        editor.enable(false)
        input.onchange = () => {
            const file = input.files[0];
            try {
                const link = saveToServer(file, editor);
                editor.enable(true)

            } catch (err) {
                console.log("upload err:", err);
            }
        };
    };

    const saveToServer = (file, editor) => {
        const fd = new FormData();
        fd.append("uploadfile", file);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", process.env.REACT_APP_API + "/api/media", true);
       
        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === this.DONE) {

                const url = xhr.responseText

                editor.insertEmbed(editor.getSelection(), "image", url);
                return url;
            }
        });

        xhr.send(fd);
    }

    const toolbarOptions = [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ]

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    const modules = useMemo(() => ({
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize']
        }
        ,
        toolbar: {
            container: toolbarOptions,
            handlers: {
                image: imageHandler
            }
        },
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        }
    }), []);

    const handleOnBlur = () => {
        onchange(description);
        console.log("linked",description)
        console.log(value)
    };


    return (
        <div>

            <ReactQuill

                ref={quillRef}
                value={description}
                onChange={handleChange}
               
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder="Write something awesome..."
            />
        </div>
    )
}