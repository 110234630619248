import { useState, useEffect, useCallback } from "react";
import axios from "axios";

function useFetch(query, page) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [lastPage, setLastPage] = useState(false)

  const sendQuery = useCallback( async () => {
    try {
      let limit = 2
      setLoading(true);
      setError(false);
      const res = await axios.get(process.env.REACT_APP_API +
          `/api/get/allposts?page=${page}&limit=${limit}&query=${query}`
        );
      
      
      if (res.data.length === 0){
          setLastPage(true)
      }
      if (query) {
        setList([])
      }
       setList((prev) =>
        
       [
        ...new Set([...prev, ...res.data])
      ]);
      setLoading(false);
    } catch (err) {
      setError(err);
    }
  }, [query, page,]);

  useEffect(() => {
    sendQuery(query);
  }, [query, sendQuery, page]);

  return { loading, error, list ,lastPage};
}

export default useFetch;
