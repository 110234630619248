import React, { useEffect, useContext } from 'react';
import Context from './context';
import axios from 'axios';


const InitAuth = () => {
  const context = useContext(Context)

  const getAccessToken = () => {
    if (localStorage.getItem('access_token')) {
      const accessToken = localStorage.getItem('access_token')
      return accessToken
    } else {
      return null
    }
  }

  const getProfile = async () => {
    let accessToken = getAccessToken()
    if (accessToken) {
          context.handleUserLogin()
          const userProfile = JSON.parse(localStorage.getItem('profile'))
          context.handleUserAddProfile(userProfile)
          console.log(userProfile)
          await axios.get(process.env.REACT_APP_API +'/api/get/userprofilefromdb', { params: { email: userProfile.email } })
            .then(res => context.handleAddDBProfile(res.data))
        }
      
    
  }

  useEffect( async () =>  {
    if (context.authObj.isAuthenticated()) {
      await getProfile()
    }
  }
    ,
    [context.authObj.profile, ])
  return (
    <div>
    </div>
  )
}

export default InitAuth;